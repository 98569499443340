export const validate = values => {
    const error = {};
    if(!values.nombre){
        error.nombre = 'Escribe el nombre por favor.';
    }
    if (!values.correo) {
        error.correo = 'Escribe el correo por favor.';
    }
    if(!values.asunto){
        error.asunto = 'Escribe el asunto por favor.';
    }
    if(!values.mensaje){
        error.mensaje = 'Escribe el mensaje por favor.';
    }
    return error;
};
