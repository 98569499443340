import React, {memo} from "react";
import {ComposableMap, Geographies, Geography} from "react-simple-maps";
import mexicoStates from './mexicoStates.json';

const MapMexico = ({setTooltipContent, data}) => {
    return (
        <div className="map-mexico-container">
            <ComposableMap viewBox="0 60 800 530" data-tip="" projection="geoAzimuthalEqualArea"
                           projectionConfig={{rotate: [100.0, -25.0, 0], scale: 1600}}>
                <Geographies geography={mexicoStates}>
                    {({geographies}) =>
                        geographies.map(geo => {
                            const state = data.find(state => state.name === geo.properties.NAME_1);
                            if(state !== undefined){
                                return (
                                    <Geography
                                        key={geo.rsmKey}
                                        geography={geo}
                                        onMouseEnter={() => {
                                            const {NAME_1} = geo.properties;
                                            setTooltipContent(`${NAME_1}`);
                                        }}
                                        onMouseLeave={() => {
                                            setTooltipContent("");
                                        }}
                                        fill={state.color}
                                        style={{
                                            default: {outline: "none", stroke: "#fff", strokeWidth: 0.1},
                                            hover: {outline: "none", stroke: "#fff"},
                                            pressed: {outline: "none", stroke: "#fff"}
                                        }}
                                    />
                                )
                            }else{
                                return ('');
                            }
                        })
                    }
                </Geographies>
            </ComposableMap>
        </div>
    );
};

export default memo(MapMexico);
