import React, { Component } from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import HomeContainer from './sitio-web/containers/home/HomeContainer';
import CSCCountryContainer from './sitio-web/containers/capital_socioeconomico_cultural/CSCCountryContainer';
import ContactContainer from "./sitio-web/containers/contact/ContactContainer";
import CNCountryContainer from "./sitio-web/containers/capital_natural/CNCountryContainer";
import StateContainer from "./sitio-web/containers/state-results/StateContainer";

class App extends Component {
  render(){
    return (
      <Router>
          <Route exact path="/" component={HomeContainer} />
          <Route exact path="/contacto" component={ContactContainer} />
          <Route exact path="/csc-nacional" component={CSCCountryContainer} />
          <Route exact path="/csc-estatal" component={StateContainer} />
          <Route exact path="/cn-nacional" component={CNCountryContainer} />
          <Route exact path="/cn-estatal" component={StateContainer} />
      </Router>
    );
  }
}

export default App;
