import React, { Component } from 'react';
import { reduxForm, Field } from 'redux-form';

const validate = values => {
  const error = {};
  if(!values.anio){
    error.anio = 'Selecciona el año por favor.';
  }
  if (!values.indice) {
    error.indice = 'Selecciona el índice por favor.';
  }
  return error;
};

class FilterResultsCountry extends Component {
  renderSelect = ({input, meta, empty, options }) => (
      <div className="form-group">
        <select className="form-control" {...input} disabled={options.length === 0}>
          <option value="">{empty}</option>
          {options.length > 0 && options.map(element => {
            return (<option key={element.key} value={element.nombre}>{element.nombre}</option>);
          })}
        </select>
        { meta.touched && meta.error && <span>{meta.error}</span> }
      </div>
  )

  render() {
    const {handleSubmit, submitting, pristine, anios, indices } = this.props;
    return (
      <div className="filter-form-container">
        <form onSubmit={handleSubmit}>
          <div className="filter-col">
            <Field name="anio" component={this.renderSelect} options={anios} empty="Selecciona un año..." />
          </div>
          <div className="filter-col">
            <Field name="indice" component={this.renderSelect} options={indices} empty="Selecciona un índice..." />
          </div>
          <div className="filter-col">
            <button type="submit" disabled={pristine || submitting} >Buscar</button>
          </div>
        </form>
      </div>
    );
  }
}

const FilterResultsCountryForm = reduxForm({
  form: 'FilterResultsCountryForm',
  validate
})(FilterResultsCountry);

export default FilterResultsCountryForm;
