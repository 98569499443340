import React, {Component} from 'react';
import firebase from "../../../Firebase";
import FilterResultsStateForm from "../../components/results-filter/FilterResultsState";
import AppFrame from "../../components/AppFrame";
import CircularProgress from "../../components/circular-progress/CircularProgress";
import {Table, Tbody, Thead, Td, Th, Tr} from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';

class StateContainer extends Component {
    constructor(props){
        super(props);
        this.db = firebase.firestore();
        this.state = {
            activeYear: new Date().getFullYear(),
            activeState: '',
            yearList: [],
            statesList: [],
            indexCNList: [],
            indexCSCList: [],
            dataCN: [],
            dataCSC: [],
            activeDonut: '',
            activeListCN: [],
            activeListCSC: []
        };
        this.tableCSC = this.db.collection('capital_socioeconomico_cultural');
        this.tableIndexesCSC = this.db.collection('csc_indices').orderBy('orden','asc');
        this.tableCN = this.db.collection('capital_natural');
        this.tableIndexesCN = this.db.collection('cn_indices').orderBy('orden','asc');
        this.tableDonuts = this.db.collection('donas');
    }

    componentDidMount() {
        this.tableCN.onSnapshot(this.getInitialData);
        this.tableIndexesCSC.onSnapshot(this.getIndexesCSC);
        this.tableIndexesCN.onSnapshot(this.getIndexesCN);
    }

    getIndexesCSC = (querySnapshot) => {
        const indexCSCList = [];
        querySnapshot.forEach((doc) => {
            const { nombre, descripcion } = doc.data();
            indexCSCList.push({ key: doc.id, nombre, descripcion});
        });
        this.setState({ indexCSCList });
    }

    getIndexesCN = (querySnapshot) => {
        const indexCNList = [];
        querySnapshot.forEach((doc) => {
            const { nombre, descripcion } = doc.data();
            indexCNList.push({ key: doc.id, nombre, descripcion});
        });
        this.setState({ indexCNList });
    }

    getInitialData = (querySnapshot) => {
        const yearList = [];
        const statesList = [];
        querySnapshot.forEach((doc) => {
            const { anio, estados } = doc.data();
            yearList.push({ key: doc.id, nombre: anio });
            estados.forEach( (estado) => {
               statesList.push({ key: estado.id, nombre: estado.name});
            });
        });
        if(statesList.length > 0 && yearList.length > 0){
            this.getStateCSCData(statesList[0].nombre, this.state.activeYear);
            this.getStateCNData(statesList[0].nombre, this.state.activeYear);
            this.getDonut(statesList[0].nombre, this.state.activeYear);
            this.setState({ activeState: statesList[0].nombre, yearList, statesList });
        }
    }

    getStateCNData = (state, year) => {
        const dataCN = [];
        const activeListCN = [];
        this.tableCN.where('anio','==',Number(year)).get().then( snap => {
            snap.forEach(doc => {
                const {estados} = doc.data();
                estados.forEach( estado => {
                    if(estado.name === state){
                        const {
                            emisiones_cov,
                            degradacion_quimica,
                            degradacion_fisica,
                            consumo_agua,
                            contaminacion_agua,
                            importacion_agua_virtual,
                            perdida_vegetal,
                            calidad_aire,
                            material_footprint,
                            especies_rojo
                        } = estado;
                        dataCN.push({
                            key: 1,
                            name: 'Emisiones de COV',
                            description: this.state.indexCNList.find(x => x.nombre === 'Emisiones de COV').descripcion,
                            percentage: this.getPercentageCN(1, emisiones_cov),
                            value: emisiones_cov !== false ? emisiones_cov.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : 'NA',
                            color: this.getColorCN(1, emisiones_cov)
                        });
                        activeListCN.push({
                            key: 15,
                            name: 'Emisiones de COV',
                            grade: emisiones_cov !== false ? emisiones_cov.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : 'NA'
                        });
                        dataCN.push({
                            key: 2,
                            name: 'Degradación química de suelos',
                            description: this.state.indexCNList.find(x => x.nombre === 'Degradación química de suelos').descripcion,
                            percentage: this.getPercentageCN(2, degradacion_quimica),
                            value: degradacion_quimica !== false ? degradacion_quimica.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : 'NA',
                            color: this.getColorCN(2, degradacion_quimica)
                        });
                        activeListCN.push({
                            key: 16,
                            name: 'Degradación química de suelos',
                            grade: degradacion_quimica !== false ? degradacion_quimica.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : 'NA'
                        });
                        dataCN.push({
                            key: 3,
                            name: 'Degradación física de suelos',
                            description: this.state.indexCNList.find(x => x.nombre === 'Degradación física de suelos').descripcion,
                            percentage: this.getPercentageCN(3, degradacion_fisica),
                            value: degradacion_fisica !== false ? degradacion_fisica.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : 'NA',
                            color: this.getColorCN(3, degradacion_fisica)
                        });
                        activeListCN.push({
                            key: 17,
                            name: 'Degradación física de suelos',
                            grade: degradacion_fisica !== false ? degradacion_fisica.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : 'NA'
                        });
                        dataCN.push({
                            key: 4,
                            name: 'Consumo de agua',
                            description: this.state.indexCNList.find(x => x.nombre === 'Consumo de agua').descripcion,
                            percentage: this.getPercentageCN(4, consumo_agua),
                            value: consumo_agua !== false ? consumo_agua.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : 'NA',
                            color: this.getColorCN(4, consumo_agua)
                        });
                        activeListCN.push({
                            key: 18,
                            name: 'Consumo de agua',
                            grade: consumo_agua !== false ? consumo_agua.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : 'NA'
                        });
                        dataCN.push({
                            key: 5,
                            name: 'Contaminación de cuerpos de agua',
                            description: this.state.indexCNList.find(x => x.nombre === 'Contaminación de cuerpos de agua').descripcion,
                            percentage: this.getPercentageCN(5, contaminacion_agua),
                            value: contaminacion_agua !== false ? contaminacion_agua.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : 'NA',
                            color: this.getColorCN(5, contaminacion_agua)
                        });
                        activeListCN.push({
                            key: 19,
                            name: 'Contaminación de cuerpos de agua',
                            grade: contaminacion_agua !== false ? contaminacion_agua.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : 'NA'
                        });
                        dataCN.push({
                            key: 6,
                            name: 'Importación de agua virtual',
                            description: this.state.indexCNList.find(x => x.nombre === 'Importación de agua virtual').descripcion,
                            percentage: this.getPercentageCN(6, importacion_agua_virtual),
                            value: importacion_agua_virtual !== false ? importacion_agua_virtual.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : 'NA',
                            color: this.getColorCN(6, importacion_agua_virtual)
                        });
                        activeListCN.push({
                            key: 20,
                            name: 'Importación de agua virtual',
                            grade: importacion_agua_virtual !== false ? importacion_agua_virtual.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : 'NA'
                        });
                        dataCN.push({
                            key: 7,
                            name: 'Pérdida de cobertura vegetal',
                            description: this.state.indexCNList.find(x => x.nombre === 'Pérdida de cobertura vegetal').descripcion,
                            percentage: this.getPercentageCN(7, perdida_vegetal),
                            value: perdida_vegetal !== false ? perdida_vegetal.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : 'NA',
                            color: this.getColorCN(7, perdida_vegetal)
                        });
                        activeListCN.push({
                            key: 21,
                            name: 'Pérdida de cobertura vegetal',
                            grade: perdida_vegetal !== false ? perdida_vegetal.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : 'NA'
                        });
                        dataCN.push({
                            key: 8,
                            name: 'Calidad del aire',
                            description: this.state.indexCNList.find(x => x.nombre === 'Calidad del aire').descripcion,
                            percentage: this.getPercentageCN(8, calidad_aire),
                            value: calidad_aire !== false ? calidad_aire.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : 'NA',
                            color: this.getColorCN(8, calidad_aire)
                        });
                        activeListCN.push({
                            key: 22,
                            name: 'Calidad del aire',
                            grade: calidad_aire !== false ? calidad_aire.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : 'NA'
                        });
                        dataCN.push({
                            key: 9,
                            name: 'Material footprint',
                            description: this.state.indexCNList.find(x => x.nombre === 'Material footprint').descripcion,
                            percentage: this.getPercentageCN(9, material_footprint),
                            value: material_footprint !== false ? material_footprint.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : 'NA',
                            color: this.getColorCN(9, material_footprint)
                        });
                        activeListCN.push({
                            key: 23,
                            name: 'Material footprint',
                            grade: material_footprint !== false ? material_footprint.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : 'NA'
                        });
                        dataCN.push({
                            key: 10,
                            name: 'Especies en Libro Rojo IUCN',
                            description: this.state.indexCNList.find(x => x.nombre === 'Especies en Libro Rojo IUCN').descripcion,
                            percentage: this.getPercentageCN(10, especies_rojo),
                            value: especies_rojo !== false ? especies_rojo.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : 'NA',
                            color: this.getColorCN(10, especies_rojo)
                        });
                        activeListCN.push({
                            key: 24,
                            name: 'Especies en Libro Rojo IUCN',
                            grade: especies_rojo !== false ? especies_rojo.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : 'NA'
                        });
                    }
                });
                this.setState({ dataCN, activeListCN });
            });
        });
    }

    getPercentageCN = (indexOrden, value) => {
        if(indexOrden === 1){
            if(value === false){
                return (0).toFixed(2);
            }else if(value > 450){
                return (100).toFixed(2);
            }else{
                return ((value * 100) / 450).toFixed(2);
            }
        }else if(indexOrden === 2){
            if(value === false){
                return (0).toFixed(2);
            }else if(value > 26){
                return (100).toFixed(2);
            }else{
                return ((value * 100) / 26).toFixed(2);
            }
        }else if(indexOrden === 3){
            if(value === false){
                return (0).toFixed(2);
            }else if(value > 26){
                return (100).toFixed(2);
            }else{
                return ((value * 100) / 26).toFixed(2);
            }
        }else if(indexOrden === 4){
            if(value === false){
                return (0).toFixed(2);
            }else if(value > 5){
                return (100).toFixed(2);
            }else{
                return ((value * 100) / 5).toFixed(2);
            }
        }else if(indexOrden === 5){
            if(value === false){
                return (0).toFixed(2);
            }else if(value > 5){
                return (100).toFixed(2);
            }else{
                return ((value * 100) / 5).toFixed(2);
            }
        }else if(indexOrden === 6){
            if(value === false){
                return (0).toFixed(2);
            }else if(value > 1){
                return (100).toFixed(2);
            }else{
                return (value * 100).toFixed(2);
            }
        }else if(indexOrden === 7){
            if(value === false){
                return (0).toFixed(2);
            }else if(value > 8){
                return (100).toFixed(2);
            }else{
                return ((value * 100) / 8).toFixed(2);
            }
        }else if(indexOrden === 8){
            if(value === false){
                return (0).toFixed(2);
            }else if(value > 5){
                return (100).toFixed(2);
            }else{
                return ((value * 100) / 5).toFixed(2);
            }
        }else if(indexOrden === 9){
            if(value === false){
                return (0).toFixed(2);
            }else if(value > 200000){
                return (100).toFixed(2);
            }else{
                return ((value * 100) / 200000).toFixed(2);
            }
        }else if(indexOrden === 10){
            if(value === false){
                return (0).toFixed(2);
            }else if(value > 200){
                return (100).toFixed(2);
            }else{
                return ((value * 100) / 200).toFixed(2);
            }
        }
    }

    getColorCN = (indexOrden, value) => {
        if(indexOrden === 1){
            if(value === false){
                return '#D6D6DA';
            }else if(value <= 50.9){
                return '#003F5C';
            }else if(value >= 51 && value <= 150.9){
                return '#444E86';
            }else if(value >= 151 && value <= 250.9){
                return '#955196';
            }else if(value >= 251 && value <= 350.9){
                return '#DD5080';
            }else if(value >= 351 && value <= 450.9){
                return '#FF6E54';
            }else if(value >= 451){
                return '#FFA601';
            }
        }else if(indexOrden === 2){
            if(value === false){
                return '#D6D6DA';
            }else if(value <= 5.9){
                return '#003F5C';
            }else if(value >= 6 && value <= 10.9){
                return '#444E86';
            }else if(value >= 11 && value <= 15.9){
                return '#955196';
            }else if(value >= 16 && value <= 20.9){
                return '#DD5080';
            }else if(value >= 21 && value <= 25.9){
                return '#FF6E54';
            }else if(value >= 26){
                return '#FFA601';
            }
        }else if(indexOrden === 3){
            if(value === false){
                return '#D6D6DA';
            }else if(value <= 5.9){
                return '#003F5C';
            }else if(value >= 6 && value <= 10.9){
                return '#444E86';
            }else if(value >= 11 && value <= 15.9){
                return '#955196';
            }else if(value >= 16 && value <= 20.9){
                return '#DD5080';
            }else if(value >= 21 && value <= 25.9){
                return '#FF6E54';
            }else if(value >= 26){
                return '#FFA601';
            }
        }else if(indexOrden === 4){
            if(value === false){
                return '#D6D6DA';
            }else if(value <= 0.99){
                return '#003F5C';
            }else if(value >= 1 && value <= 1.99){
                return '#444E86';
            }else if(value >= 2 && value <= 2.99){
                return '#955196';
            }else if(value >= 3 && value <= 3.99){
                return '#DD5080';
            }else if(value >= 4 && value <= 4.99){
                return '#FF6E54';
            }else if(value >= 5){
                return '#FFA601';
            }
        }else if(indexOrden === 5){
            if(value === false){
                return '#D6D6DA';
            }else if(value <= 0.99){
                return '#003F5C';
            }else if(value >= 1 && value <= 1.99){
                return '#444E86';
            }else if(value >= 2 && value <= 2.99){
                return '#955196';
            }else if(value >= 3 && value <= 3.99){
                return '#DD5080';
            }else if(value >= 4 && value <= 4.99){
                return '#FF6E54';
            }else if(value >= 5){
                return '#FFA601';
            }
        }else if(indexOrden === 6){
            if(value === false){
                return '#D6D6DA';
            }else if(value <= 0.2){
                return '#003F5C';
            }else if(value >= 0.21 && value <= 0.4){
                return '#444E86';
            }else if(value >= 0.41 && value <= 0.6){
                return '#955196';
            }else if(value >= 0.61 && value <= 0.8){
                return '#DD5080';
            }else if(value >= 0.81 && value <= 1){
                return '#FF6E54';
            }else if(value > 1){
                return '#FFA601';
            }
        }else if(indexOrden === 7){
            if(value === false){
                return '#D6D6DA';
            }else if(value <= 0){
                return '#003F5C';
            }else if(value >= 0.1 && value <= 2){
                return '#444E86';
            }else if(value >= 2.1 && value <= 4){
                return '#955196';
            }else if(value >= 4.1 && value <= 6){
                return '#DD5080';
            }else if(value >= 6.1 && value <= 8){
                return '#FF6E54';
            }else if(value > 8){
                return '#FFA601';
            }
        }else if(indexOrden === 8){
            if(value === false){
                return '#D6D6DA';
            }else if(value <= 0.99){
                return '#003F5C';
            }else if(value >= 1 && value <= 1.99){
                return '#444E86';
            }else if(value >= 2 && value <= 2.99){
                return '#955196';
            }else if(value >= 3 && value <= 3.99){
                return '#DD5080';
            }else if(value >= 4 && value <= 4.99){
                return '#FF6E54';
            }else if(value >= 5){
                return '#FFA601';
            }
        }else if(indexOrden === 9){
            if(value === false){
                return '#D6D6DA';
            }else if(value <= 10999.99){
                return '#003F5C';
            }else if(value >= 11000 && value <= 50999.99){
                return '#444E86';
            }else if(value >= 51000 && value <= 100999.99){
                return '#955196';
            }else if(value >= 101000 && value <= 150999.99){
                return '#DD5080';
            }else if(value >= 151000 && value <= 200999.99){
                return '#FF6E54';
            }else if(value >= 201000){
                return '#FFA601';
            }
        }else if(indexOrden === 10){
            if(value === false){
                return '#D6D6DA';
            }else if(value <= 0.99){
                return '#003F5C';
            }else if(value >= 1 && value <= 50.99){
                return '#444E86';
            }else if(value >= 51 && value <= 100.99){
                return '#955196';
            }else if(value >= 101 && value <= 150.99){
                return '#DD5080';
            }else if(value >= 151 && value <= 200.99){
                return '#FF6E54';
            }else if(value >= 201){
                return '#FFA601';
            }
        }
    }

    getStateCSCData = (state, year) => {
        const dataCSC = [];
        const activeListCSC = [];
        this.tableCSC.where('anio','==',Number(year)).get().then( snap => {
            snap.forEach(doc => {
                const {estados} = doc.data();
                estados.forEach( estado => {
                    if (estado.name === state) {
                        const {
                            progreso_social,
                            esperanza_vida,
                            cobertura_sanitaria,
                            obesidad_adultos,
                            ingresos_capita,
                            acceso_electricidad,
                            nivel_educativo,
                            apoyo_social,
                            indice_democracia,
                            equidad,
                            tasa_empleo,
                            indice_competitividad,
                            indice_paz_global,
                            transparencia
                        } = estado;
                        dataCSC.push({
                            key: 1,
                            name: 'Nivel de Progreso Social',
                            description: this.state.indexCSCList.find(x => x.nombre === 'Nivel de Progreso Social').descripcion,
                            percentage: this.getPercentageCSC(1, progreso_social),
                            value: progreso_social !== false ? progreso_social.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : 'NA',
                            color: this.getColorCSC(1, progreso_social)
                        });
                        activeListCSC.push({
                            key: 1,
                            name: 'Nivel de Progreso Social',
                            grade: progreso_social !== false ? progreso_social.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : 'NA'
                        });
                        dataCSC.push({
                            key: 2,
                            name: 'Esperanza de vida',
                            description: this.state.indexCSCList.find(x => x.nombre === 'Esperanza de vida').descripcion,
                            percentage: this.getPercentageCSC(2, esperanza_vida),
                            value: esperanza_vida !== false ? esperanza_vida.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : 'NA',
                            color: this.getColorCSC(2, esperanza_vida)
                        });
                        activeListCSC.push({
                            key: 2,
                            name: 'Esperanza de vida',
                            grade: esperanza_vida !== false ? esperanza_vida.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : 'NA'
                        });
                        dataCSC.push({
                            key: 3,
                            name: 'Cobertura sanitaria universal',
                            description: this.state.indexCSCList.find(x => x.nombre === 'Cobertura sanitaria universal').descripcion,
                            percentage: this.getPercentageCSC(3, cobertura_sanitaria),
                            value: cobertura_sanitaria !== false ? cobertura_sanitaria.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : 'NA',
                            color: this.getColorCSC(3, cobertura_sanitaria)
                        });
                        activeListCSC.push({
                            key: 3,
                            name: 'Cobertura sanitaria universal',
                            grade: cobertura_sanitaria !== false ? cobertura_sanitaria.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : 'NA'
                        });
                        dataCSC.push({
                            key: 4,
                            name: 'Obesidad en adultos',
                            description: this.state.indexCSCList.find(x => x.nombre === 'Obesidad en adultos').descripcion,
                            percentage: this.getPercentageCSC(4, obesidad_adultos),
                            value: obesidad_adultos !== false ? obesidad_adultos.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : 'NA',
                            color: this.getColorCSC(4, obesidad_adultos)
                        });
                        activeListCSC.push({
                            key: 4,
                            name: 'Obesidad en adultos',
                            grade: obesidad_adultos !== false ? obesidad_adultos.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : 'NA'
                        });
                        dataCSC.push({
                            key: 5,
                            name: 'Ingresos per cápita',
                            description: this.state.indexCSCList.find(x => x.nombre === 'Ingresos per cápita').descripcion,
                            percentage: this.getPercentageCSC(5, ingresos_capita),
                            value: ingresos_capita !== false ? ingresos_capita.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : 'NA',
                            color: this.getColorCSC(5, ingresos_capita)
                        });
                        activeListCSC.push({
                            key: 5,
                            name: 'Ingresos per cápita',
                            grade: ingresos_capita !== false ? ingresos_capita.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : 'NA'
                        });
                        dataCSC.push({
                            key: 6,
                            name: 'Acceso a la electricidad',
                            description: this.state.indexCSCList.find(x => x.nombre === 'Acceso a la electricidad').descripcion,
                            percentage: this.getPercentageCSC(6, acceso_electricidad),
                            value: acceso_electricidad !== false ? acceso_electricidad.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : 'NA',
                            color: this.getColorCSC(6, acceso_electricidad)
                        });
                        activeListCSC.push({
                            key: 6,
                            name: 'Acceso a la electricidad',
                            grade: acceso_electricidad !== false ? acceso_electricidad.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : 'NA'
                        });
                        dataCSC.push({
                            key: 7,
                            name: 'Nivel educativo',
                            description: this.state.indexCSCList.find(x => x.nombre === 'Nivel educativo').descripcion,
                            percentage: this.getPercentageCSC(7, nivel_educativo),
                            value: nivel_educativo !== false ? nivel_educativo.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : 'NA',
                            color: this.getColorCSC(7, nivel_educativo)
                        });
                        activeListCSC.push({
                            key: 7,
                            name: 'Nivel educativo',
                            grade: nivel_educativo !== false ? nivel_educativo.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : 'NA'
                        });
                        dataCSC.push({
                            key: 8,
                            name: 'Apoyo social',
                            description: this.state.indexCSCList.find(x => x.nombre === 'Apoyo social').descripcion,
                            percentage: this.getPercentageCSC(8, apoyo_social),
                            value: apoyo_social !== false ? apoyo_social.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : 'NA',
                            color: this.getColorCSC(8, apoyo_social)
                        });
                        activeListCSC.push({
                            key: 8,
                            name: 'Apoyo social',
                            grade: apoyo_social !== false ? apoyo_social.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : 'NA'
                        });
                        dataCSC.push({
                            key: 9,
                            name: 'Índice de democracia',
                            description: this.state.indexCSCList.find(x => x.nombre === 'Índice de democracia').descripcion,
                            percentage: this.getPercentageCSC(9, indice_democracia),
                            value: indice_democracia !== false ? indice_democracia.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : 'NA',
                            color: this.getColorCSC(9, indice_democracia)
                        });
                        activeListCSC.push({
                            key: 9,
                            name: 'Índice de democracia',
                            grade: indice_democracia !== false ? indice_democracia.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : 'NA'
                        });
                        dataCSC.push({
                            key: 10,
                            name: 'Equidad',
                            description: this.state.indexCSCList.find(x => x.nombre === 'Equidad').descripcion,
                            percentage: this.getPercentageCSC(10, equidad),
                            value: equidad !== false ? equidad.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : 'NA',
                            color: this.getColorCSC(10, equidad)
                        });
                        activeListCSC.push({
                            key: 10,
                            name: 'Equidad',
                            grade: equidad !== false ? equidad.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : 'NA'
                        });
                        dataCSC.push({
                            key: 11,
                            name: 'Tasa de empleo',
                            description: this.state.indexCSCList.find(x => x.nombre === 'Tasa de empleo').descripcion,
                            percentage: this.getPercentageCSC(11, tasa_empleo),
                            value: tasa_empleo !== false ? tasa_empleo.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : 'NA',
                            color: this.getColorCSC(11, tasa_empleo)
                        });
                        activeListCSC.push({
                            key: 11,
                            name: 'Tasa de empleo',
                            grade: tasa_empleo !== false ? tasa_empleo.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : 'NA'
                        });
                        dataCSC.push({
                            key: 12,
                            name: 'Índice de competitividad',
                            description: this.state.indexCSCList.find(x => x.nombre === 'Índice de competitividad').descripcion,
                            percentage: this.getPercentageCSC(12, indice_competitividad),
                            value: indice_competitividad !== false ? indice_competitividad.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : 'NA',
                            color: this.getColorCSC(12, indice_competitividad)
                        });
                        activeListCSC.push({
                            key: 12,
                            name: 'Índice de competitividad',
                            grade: indice_competitividad !== false ? indice_competitividad.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : 'NA'
                        });
                        dataCSC.push({
                            key: 13,
                            name: 'Índice de paz global',
                            description: this.state.indexCSCList.find(x => x.nombre === 'Índice de paz global').descripcion,
                            percentage: this.getPercentageCSC(13, indice_paz_global),
                            value: indice_paz_global !== false ? indice_paz_global.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : 'NA',
                            color: this.getColorCSC(13, indice_paz_global)
                        });
                        activeListCSC.push({
                            key: 13,
                            name: 'Índice de paz global',
                            grade: indice_paz_global !== false ? indice_paz_global.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : 'NA'
                        });
                        dataCSC.push({
                            key: 14,
                            name: 'Transparencia',
                            description: this.state.indexCSCList.find(x => x.nombre === 'Transparencia').descripcion,
                            percentage: this.getPercentageCSC(14, transparencia),
                            value: transparencia !== false ? transparencia.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : 'NA',
                            color: this.getColorCSC(14, transparencia)
                        });
                        activeListCSC.push({
                            key: 14,
                            name: 'Transparencia',
                            grade: transparencia !== false ? transparencia.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : 'NA'
                        });
                    }
                });
                this.setState({ dataCSC, activeListCSC });
            });
        });
    }

    getPercentageCSC = (indexOrden, value) => {
        if(indexOrden === 1){
            if(value === false){
                return (0).toFixed(2);
            }else if(value > 100){
                return (100).toFixed(2);
            }else{
                return ((value * 100) / 100).toFixed(2);
            }
        }else if(indexOrden === 2){
            if(value === false){
                return (0).toFixed(2);
            }else if(value > 80){
                return (100).toFixed(2);
            }else{
                return ((value * 100) / 80).toFixed(2);
            }
        }else if(indexOrden === 3){
            if(value === false){
                return (0).toFixed(2);
            }else if(value > 100){
                return (100).toFixed(2);
            }else{
                return ((value * 100) / 100).toFixed(2);
            }
        }else if(indexOrden === 4){
            if(value === false){
                return (0).toFixed(2);
            }else if(value > 100){
                return (100).toFixed(2);
            }else{
                return ((value * 100) / 100).toFixed(2);
            }
        }else if(indexOrden === 5){
            if(value === false){
                return (0).toFixed(2);
            }else if(value > 500000){
                return (100).toFixed(2);
            }else{
                return ((value * 100) / 500000).toFixed(2);
            }
        }else if(indexOrden === 6){
            if(value === false){
                return (0).toFixed(2);
            }else if(value > 100){
                return (100).toFixed(2);
            }else{
                return ((value * 100) / 100).toFixed(2);
            }
        }else if(indexOrden === 7){
            if(value === false){
                return (0).toFixed(2);
            }else if(value > 100){
                return (100).toFixed(2);
            }else{
                return ((value * 100) / 100).toFixed(2);
            }
        }else if(indexOrden === 8){
            if(value === false){
                return (0).toFixed(2);
            }else if(value > 100){
                return (100).toFixed(2);
            }else{
                return ((value * 100) / 100).toFixed(2);
            }
        }else if(indexOrden === 9){
            if(value === false){
                return (0).toFixed(2);
            }else if(value > 10000){
                return (100).toFixed(2);
            }else{
                return ((value * 100) / 10000).toFixed(2);
            }
        }else if(indexOrden === 10){
            if(value === false){
                return (0).toFixed(2);
            }else if(value > 0){
                return (100).toFixed(2);
            }else{
                return ((value * 100) / 0).toFixed(2);
            }
        }else if(indexOrden === 11){
            if(value === false){
                return (0).toFixed(2);
            }else if(value > 100){
                return (100).toFixed(2);
            }else{
                return ((value * 100) / 100).toFixed(2);
            }
        }else if(indexOrden === 12){
            if(value === false){
                return (0).toFixed(2);
            }else if(value > 100){
                return (100).toFixed(2);
            }else{
                return ((value * 100) / 100).toFixed(2);
            }
        }else if(indexOrden === 13){
            if(value === false){
                return (0).toFixed(2);
            }else if(value > 0){
                return (100).toFixed(2);
            }else{
                return ((value * 100) / 0).toFixed(2);
            }
        }else if(indexOrden === 14){
            if(value === false){
                return (0).toFixed(2);
            }else if(value > 126){
                return (100).toFixed(2);
            }else{
                return ((value * 100) / 126).toFixed(2);
            }
        }
    }

    getColorCSC = (indexOrden, value) => {
        if(indexOrden === 1){
            if(value === false){
                return '#D6D6DA';
            }else if(value > 90){
                return '#003F5C';
            }else if(value <= 90 && value >= 85){
                return '#444E86';
            }else if(value < 85 && value >= 80){
                return '#955196';
            }else if(value < 80 && value >= 75){
                return '#DD5080';
            }else if(value < 75 && value >= 70){
                return '#FF6E54';
            }else if(value < 70){
                return '#FFA601';
            }
        }else if(indexOrden === 2){
            if(value === false){
                return '#D6D6DA';
            }else if(value > 90){
                return '#003F5C';
            }else if(value <= 90 && value >= 85){
                return '#444E86';
            }else if(value < 85 && value >= 80){
                return '#955196';
            }else if(value < 80 && value >= 75){
                return '#DD5080';
            }else if(value < 75 && value >= 70){
                return '#FF6E54';
            }else if(value < 70){
                return '#FFA601';
            }
        }else if(indexOrden === 3){
            if(value === false){
                return '#D6D6DA';
            }else if(value > 90){
                return '#003F5C';
            }else if(value <= 90 && value >= 80){
                return '#444E86';
            }else if(value < 80 && value >= 70){
                return '#955196';
            }else if(value < 70 && value >= 60){
                return '#DD5080';
            }else if(value < 60 && value >= 50){
                return '#FF6E54';
            }else if(value < 50){
                return '#FFA601';
            }
        }else if(indexOrden === 4){
            if(value === false){
                return '#D6D6DA';
            }else if(value <= 7.39){
                return '#003F5C';
            }else if(value >= 7.4 && value <= 9.5){
                return '#444E86';
            }else if(value >= 9.6 && value <= 10.7){
                return '#955196';
            }else if(value >= 10.8 && value <= 11.9){
                return '#DD5080';
            }else if(value >= 12 && value <= 14.9){
                return '#FF6E54';
            }else if(value >= 15){
                return '#FFA601';
            }
        }else if(indexOrden === 5){
            if(value === false){
                return '#D6D6DA';
            }else if(value > 500000){
                return '#003F5C';
            }else if(value <= 500000 && value >= 400000){
                return '#444E86';
            }else if(value < 400000 && value >= 300000){
                return '#955196';
            }else if(value < 300000 && value >= 200000){
                return '#DD5080';
            }else if(value < 200000 && value >= 100000){
                return '#FF6E54';
            }else if(value < 100000){
                return '#FFA601';
            }
        }else if(indexOrden === 6){
            if(value === false){
                return '#D6D6DA';
            }else if(value >= 99){
                return '#003F5C';
            }else if(value <= 98.99 && value >= 96){
                return '#444E86';
            }else if(value <= 95.99 && value >= 93){
                return '#955196';
            }else if(value <= 92.99 && value >= 90){
                return '#DD5080';
            }else if(value <= 89.99 && value >= 87){
                return '#FF6E54';
            }else if(value <= 86.99){
                return '#FFA601';
            }
        }else if(indexOrden === 7){
            if(value === false){
                return '#D6D6DA';
            }else if(value > 80){
                return '#003F5C';
            }else if(value <= 80 && value >= 65){
                return '#444E86';
            }else if(value < 65 && value >= 49){
                return '#955196';
            }else if(value < 49 && value >= 33){
                return '#DD5080';
            }else if(value < 33 && value >= 16){
                return '#FF6E54';
            }else if(value < 16){
                return '#FFA601';
            }
        }else if(indexOrden === 8){
            if(value === false){
                return '#D6D6DA';
            }else if(value < 16){
                return '#003F5C';
            }else if(value >= 16 && value <= 33){
                return '#444E86';
            }else if(value > 33 && value <= 49){
                return '#955196';
            }else if(value > 49 && value <= 65){
                return '#DD5080';
            }else if(value > 65 && value <= 80){
                return '#FF6E54';
            }else if(value > 80){
                return '#FFA601';
            }
        }else if(indexOrden === 9){
            if(value === false){
                return '#D6D6DA';
            }else if(value > 8300){
                return '#003F5C';
            }else if(value <= 8300 && value >= 6600){
                return '#444E86';
            }else if(value < 6600 && value >= 5000){
                return '#955196';
            }else if(value < 5000 && value >= 3300){
                return '#DD5080';
            }else if(value < 3300 && value >= 1600){
                return '#FF6E54';
            }else if(value < 1600){
                return '#FFA601';
            }
        }else if(indexOrden === 10){
            if(value === false){
                return '#D6D6DA';
            }else if(value < 1){
                return '#003F5C';
            }else if(value >= 1 && value <= 2){
                return '#444E86';
            }else if(value > 2 && value <= 3){
                return '#955196';
            }else if(value > 3 && value <= 6){
                return '#DD5080';
            }else if(value > 6 && value <= 7.5){
                return '#FF6E54';
            }else if(value > 7.5){
                return '#FFA601';
            }
        }else if(indexOrden === 11){
            if(value === false){
                return '#D6D6DA';
            }else if(value > 90){
                return '#003F5C';
            }else if(value <= 90 && value >= 80){
                return '#444E86';
            }else if(value < 80 && value >= 70){
                return '#955196';
            }else if(value < 70 && value >= 60){
                return '#DD5080';
            }else if(value < 60 && value >= 50){
                return '#FF6E54';
            }else if(value < 50){
                return '#FFA601';
            }
        }else if(indexOrden === 12){
            if(value === false){
                return '#D6D6DA';
            }else if(value > 90){
                return '#003F5C';
            }else if(value <= 90 && value >= 80){
                return '#444E86';
            }else if(value < 80 && value >= 70){
                return '#955196';
            }else if(value < 70 && value >= 60){
                return '#DD5080';
            }else if(value < 60 && value >= 50){
                return '#FF6E54';
            }else if(value < 50){
                return '#FFA601';
            }
        }else if(indexOrden === 13){
            if(value === false){
                return '#D6D6DA';
            }else if(value < 1){
                return '#003F5C';
            }else if(value >= 1 && value <= 2){
                return '#444E86';
            }else if(value > 2 && value <= 2.54){
                return '#955196';
            }else if(value > 2.54 && value <= 2.92){
                return '#DD5080';
            }else if(value > 2.92 && value <= 3.9){
                return '#FF6E54';
            }else if(value > 3.9){
                return '#FFA601';
            }
        }else if(indexOrden === 14){
            if(value === false){
                return '#D6D6DA';
            }else if(value >= 126){
                return '#003F5C';
            }else if(value <= 125.99 && value >= 101){
                return '#444E86';
            }else if(value <= 100.99 && value >= 76){
                return '#955196';
            }else if(value <= 75.99 && value >= 51){
                return '#DD5080';
            }else if(value <= 50.99 && value >= 26){
                return '#FF6E54';
            }else if(value <= 25.99){
                return '#FFA601';
            }
        }
    }

    getDonut = (state, year) => {
        this.tableDonuts.where('anio','==',Number(year)).get().then( snap => {
            snap.forEach(doc => {
                const {estados} = doc.data();
                estados.forEach(estado => {
                    if (estado.name === state) {
                        const { dona } = estado;
                        this.setState({activeDonut: dona});
                    }
                });
            });
        });
    }

    handleSubmit = (values) => {
        const { anio, estado } = values;
        this.getStateCSCData(estado, anio);
        this.getStateCNData(estado, anio);
        this.getDonut(estado, anio);
        this.setState({ activeYear: anio, activeState: estado });
    }

    renderBody(){
        return (
            <div className="results-state-container">
                <FilterResultsStateForm anios={this.state.yearList} estados={this.state.statesList} onSubmit={this.handleSubmit} />
                <div className="title_section">
                    <h2>{this.state.activeState} {this.state.activeYear}</h2>
                </div>
                <div className="content">
                    <div className="description">
                        <p>Ésta es la brújula de hacia dónde debemos dirigir nuestros esfuerzos de manera individual y como país. La gráfica es una foto de <span>la realidad de {this.state.activeState}.</span></p>
                        {
                            this.state.activeDonut !== '' && this.state.activeListCSC.length > 0 && this.state.activeListCN.length > 0 ?
                                <div className="donut-container">
                                    <div className="column">
                                        <img src={this.state.activeDonut} alt="La Realidad de México" />
                                        <div className="donut-footnote">
                                            <p>En la gráfica, el área en morado es la situación en la que queremos estar, sin escasez en lo social y sin excedentes que dañen progresivamente el planeta. Las áreas en amarillo hacia el interior muestran la escasez en los indicadores sociales. Mientras tanto, las áreas en amarillo hacia el exterior muestran la sobreexplotación en nuestros ecosistemas.</p>
                                        </div>
                                    </div>
                                    <div className="column">
                                        <Table>
                                            <Thead>
                                                <Tr>
                                                    <Th className="indicator">Indicador</Th>
                                                    <Th>Calificación</Th>
                                                </Tr>
                                            </Thead>
                                            <Tbody>
                                                {
                                                    this.state.activeListCSC.map( (indicator) => {
                                                        return(
                                                            <Tr key={indicator.key}>
                                                                <Td className="indicator">{indicator.name}</Td>
                                                                <Td>{indicator.grade}</Td>
                                                            </Tr>
                                                        )
                                                    })
                                                }
                                                {
                                                    this.state.activeListCN.map( (indicator) => {
                                                        return(
                                                            <Tr key={indicator.key}>
                                                                <Td className="indicator">{indicator.name}</Td>
                                                                <Td>{indicator.grade}</Td>
                                                            </Tr>
                                                        )
                                                    })
                                                }
                                            </Tbody>
                                        </Table>
                                    </div>
                                </div>
                                : ''
                        }
                        {
                            this.state.dataCSC.length > 0 && this.state.dataCN.length > 0 ?
                                <div className="capital-row">
                                    <div className="column">
                                        <div className="title">
                                            <h5>Capital Socioeconómico y Cultural</h5>
                                            <p>Conoce la situación, propuestas y distribución de sostenibilidad social y economía regenerativa de nuestro país</p>
                                        </div>
                                        <div className="indexes">
                                            {
                                                this.state.dataCSC.map( index => {
                                                    return(
                                                        <div className="index" key={index.key}>
                                                            <CircularProgress percentage={index.percentage} value={index.value} name={index.name} description={index.description} color={index.color} />
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                    </div>
                                    <div className="column">
                                        <div className="title">
                                            <h5>Capital Natural</h5>
                                            <p>Conoce la situación, propuestas y distribución del techo ecológico de nuestro país</p>
                                        </div>
                                        <div className="indexes">
                                            {
                                                this.state.dataCN.map( index => {
                                                    return(
                                                        <div className="index" key={index.key}>
                                                            <CircularProgress percentage={index.percentage} value={index.value} name={index.name} description={index.description} color={index.color} />
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                    </div>
                                </div>
                                : ''
                        }
                        <p>Basados en el modelo de Economía de Kate Raworth, profesora de la Universidad de Oxford en el Reino Unido, se considera que <span>las políticas públicas y economía a impulsar deben ser distributivas y regenerativas por naturaleza.</span> Es decir, que se cubra una <span>base social</span> necesaria para ofrecer oportunidades de desarrollo y realización personal (Acceso a la salud, seguridad, alimentación, educación de calidad, oportunidades diversas de trabajo bien remunerado, entre otros) al mismo tiempo que respetemos el <span>techo ecológico de nuestro planeta</span> (Disminución de nuestra huella de carbono, decrecer la tasa de deforestación y de extinción de la biodiversidad, aumentar la calidad de los cuerpos de agua, etc.) del cual todos dependemos.</p>
                        <p>Los beneficios de este modelo aumentará el Producto Interno Bruto del país por décadas sin tener que sacrificar la sostenibilidad o el futuro de varias generaciones como lo hacen otros modelos económicos existentes en este momento.</p>
                        <p>El resultado esperado es acelerar:</p>
                        <ol>
                            <li>El desarrollo y fortalecimiento del <span>capital socioeconómico y cultural</span> que reside en cada mexicano.</li>
                            <li>La restauración y protección del <span>capital natural</span> del país.</li>
                        </ol>
                        <p>A partir de este modelo, con ayuda de un <span>sistema de democracia digital</span>, se construirá una plataforma de políticas públicas e iniciativas puntuales basadas en el <span>análisis sistemático y reconocimiento de todos aquellos actores</span> que colaboran para mejorar nuestro día a día y en beneficio de todos.</p>
                    </div>
                </div>
            </div>
        );
    }

    render() {
        return (
            <AppFrame body={this.renderBody()} />
        );
    }
}

export default StateContainer;
