import React, {Component} from 'react';
import AppFrame from './../../components/AppFrame';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
    faUniversity,
    faHandHoldingUsd,
    faPeopleCarry,
    faUsers,
    faBalanceScale,
    faComments,
    faDove,
    faGlobeAmericas,
    faTractor,
    faWater
} from '@fortawesome/free-solid-svg-icons';
import {faLeanpub} from '@fortawesome/free-brands-svg-icons';
import logoWhite from '../../images/logo_blanco.png';
import covidPDF from '../../data/covid-19.pdf';
import reactivacionEconomicaPDF from '../../data/reactivacion_economica_2020.pdf';

class HomeContainer extends Component {
    openPDF = (pdf) => {
        window.open(pdf);
    }

    renderBody() {
        return (
            <div className="home-container">
                <div className="slide">
                    <img src={logoWhite} alt="La Realidad de México"/>
                    <p>Creando Gobiernos Inteligentes</p>
                    <p className="authors">Elaborado por: Fortino Acosta Moreno e Isaac Méndez Altuzar.</p>
                </div>
                <div className="solutions section">
                    <div className="title_section">
                        <h2>Soluciones</h2>
                    </div>
                    <div className="circles">
                        <div className="circle"  onClick={() => this.openPDF(covidPDF)}>
                            <p>Pandemia</p>
                            <h3>COVID-19</h3>
                        </div>
                        <div className="circle"  onClick={() => this.openPDF(reactivacionEconomicaPDF)}>
                            <p>Reactivación</p>
                            <h3>Económica</h3>
                        </div>
                    </div>
                </div>
                <div className="section">
                    <div className="title_section">
                        <h2>¿Quiénes Somos?</h2>
                    </div>
                    <div className="fila">
                        <div className="columna">
                            <p>Somos una plataforma social convencida de innovar la política y los gobiernos aplicando
                                la democracia digital para poder solucionar de la manera más eficiente e informada los
                                retos de este siglo.</p>
                        </div>
                    </div>
                </div>
                <div className="section">
                    <div className="title_section">
                        <h2>Nuestros Valores</h2>
                    </div>
                    <div className="fila">
                        <div className="columna">
                            <p>Fortalecer gobiernos que ofrezcan certidumbre, transparencia y soluciones basadas en el
                                respeto a los derechos humanos, los principios democráticos y la protección de nuestro
                                patrimonio cultural y natural.</p>
                        </div>
                    </div>
                </div>
                <div className="principles section">
                    <div className="title_section">
                        <h2>Principios constitutivos</h2>
                    </div>
                    <div className="fila">
                        <div className="columna">
                            <FontAwesomeIcon icon={faUniversity} size="2x"/>
                            <p>Hacer del centro político la base de una Democracia participativa, progresista y
                                reformista que permita articular un nuevo modelo de convivencia social más justo e
                                igualitario.</p>
                        </div>
                        <div className="columna">
                            <FontAwesomeIcon icon={faHandHoldingUsd} size="2x"/>
                            <p>Construir un proyecto económico democrático, armónico y sostenible en lo político, lo
                                económico, lo social y lo ambiental.</p>
                        </div>
                        <div className="columna">
                            <FontAwesomeIcon icon={faPeopleCarry} size="2x"/>
                            <p>Fomentar la ciudadanía activa, el diálogo y la corresponsabilidad para hacer del bien
                                común una bandera de lucha que nos permita alcanzar una vida con dignidad y
                                libertad.</p>
                        </div>
                        <div className="columna">
                            <FontAwesomeIcon icon={faUsers} size="2x"/>
                            <p>Reconocer las diferencias de nuestra sociedad, preservando y extendiendo el pluralismo y
                                garantizando siempre la igualdad dentro de la diversidad.</p>
                        </div>
                        <div className="columna">
                            <FontAwesomeIcon icon={faBalanceScale} size="2x"/>
                            <p>Restaurar la justicia y la convivencia pacífica a través de la revitalización permanente
                                de la comunidad participativa como base del Estado de Derecho.</p>
                        </div>
                        <div className="columna">
                            <FontAwesomeIcon icon={faComments} size="2x"/>
                            <p>Someter a la autocrítica y al escrutinio público nuestras reflexiones para la toma de
                                decisiones oportunas, informadas, eficientes y realistas.</p>
                        </div>
                        <div className="columna">
                            <FontAwesomeIcon icon={faLeanpub} size="2x"/>
                            <p>Promover un modelo educativo que a través de todos los campos del saber respalde el
                                desarrollo integral del país para las siguientes generaciones.</p>
                        </div>
                        <div className="columna">
                            <FontAwesomeIcon icon={faDove} size="2x"/>
                            <p>Recobrar la confianza de la ciudadanía en la Política, haciendo de la Ética y la
                                Integridad, las bases de un gobierno honesto, transparente y eficiente.</p>
                        </div>
                        <div className="columna">
                            <FontAwesomeIcon icon={faGlobeAmericas} size="2x"/>
                            <p>Fomentar la cooperación internacional, el respeto y promoción de los Derechos Humanos y
                                el desarrollo sostenible de la Tierra.</p>
                        </div>
                        <div className="columna">
                            <FontAwesomeIcon icon={faTractor} size="2x"/>
                            <p>Invertir en la agricultura sostenible como estrategia principal de seguridad alimentaria
                                nacional y aumentar la disponibilidad de alimentos de calidad y sanos para toda la
                                población.</p>
                        </div>
                        <div className="columna">
                            <FontAwesomeIcon icon={faWater} size="2x"/>
                            <p>Recuperar de manera significativa la salud de nuestros ríos y mares desde una planeación
                                integral y gestión regional que asegure el abastecimiento de agua potable.</p>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    render() {
        return (
            <AppFrame body={this.renderBody()}/>
        );
    }
}

export default HomeContainer;
