import * as firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';

const config = {
  apiKey: "AIzaSyBphlSelqmkoA8awqAcG210rgqoxtnML28",
  authDomain: "la-realidad-mexico.firebaseapp.com",
  databaseURL: "https://la-realidad-mexico.firebaseio.com",
  projectId: "la-realidad-mexico",
  storageBucket: "la-realidad-mexico.appspot.com",
  messagingSenderId: "951606919125",
  appId: "1:951606919125:web:12eb40e63c2bf7df05075c",
  measurementId: "G-DEMVW7BP01"
};

firebase.initializeApp(config);
firebase.auth().signInWithEmailAndPassword('isaacmendez@larealidad.mx','C0mp053r5&2021').then( () => {});
export default firebase;
