import React, { useState } from 'react';
import MapMexico from './MapMexico';
import ReactTooltip from 'react-tooltip';

const RenderMap = ({ index, data }) => {
  const [content, setContent] = useState("");
  return (
    <div className="render-map-container">
        <h3>{index.nombre}</h3>
        <p>{index.descripcion}</p>
        <MapMexico setTooltipContent={setContent} data={data} />
        <ReactTooltip>{content}</ReactTooltip>
        <div className="reference">
            <p>Referencia: <a href={index.referencia} rel="noopener noreferrer" target="_blank">{index.referencia}</a></p>
        </div>
    </div>
  );
}

export default RenderMap;
