import React, { Component } from 'react';
import AppFrame from './../../components/AppFrame';
import firebase from './../../../Firebase';
import FilterResultsCountryForm from '../../components/results-filter/FilterResultsCountry';
import RenderMap from "../../components/map-render/RenderMap";
import {Table, Tbody, Thead, Td, Th, Tr} from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';

class CSCCountryContainer extends Component {
  constructor(props){
    super(props);
    this.db = firebase.firestore();
    this.state = {
      yearList: [],
      indexesList: [],
      activeIndex: { key: '', nombre: '', referencia: '', descripcion: '', orden: 0, orderSort: 'desc'},
      activeYear: new Date().getFullYear(),
      activeRanking: []
    };
    this.tableIndexes = this.db.collection('csc_indices').orderBy('orden','asc');
    this.tableCSC = this.db.collection('capital_socioeconomico_cultural');
  }

  componentDidMount() {
    this.tableIndexes.onSnapshot(this.getIndexes);
    this.tableCSC.onSnapshot(this.getYears);
  }

  getIndexes = (querySnapshot) => {
    const indexesList = [];
    querySnapshot.forEach((doc) => {
      const { nombre, referencia, descripcion, orden, orderSort } = doc.data();
      indexesList.push({ key: doc.id, nombre, referencia, descripcion, orden, orderSort});
    });
    if(indexesList.length > 0){
      this.getCountryData(indexesList[0], this.state.activeYear);
      this.setState({ indexesList, activeIndex: indexesList[0] });
    }
  }

  getYears = (querySnapshot) => {
    const yearList = [];
    querySnapshot.forEach((doc) => {
      const { anio } = doc.data();
      yearList.push({ key: doc.id, nombre: anio });
    });
    this.setState({ yearList });
  }

  getCountryData = (index, year) => {
    let countryData = [];
    this.tableCSC.where('anio','==',Number(year)).get().then( snap => {
      snap.forEach( doc => {
        const { estados } = doc.data();
        estados.forEach( estado => {
          const {
            id,
            name,
            progreso_social,
            esperanza_vida,
            cobertura_sanitaria,
            obesidad_adultos,
            ingresos_capita,
            acceso_electricidad,
            nivel_educativo,
            apoyo_social,
            indice_democracia,
            equidad,
            tasa_empleo,
            indice_competitividad,
            indice_paz_global,
            transparencia
          } = estado;
          if(index.orden === 1){
            const value = !progreso_social ? 0 : progreso_social;
            const color = this.getColor(index.orden, progreso_social);
            countryData.push({id, name, value, color, grade: progreso_social});
          }else if(index.orden === 2){
            const value = !esperanza_vida ? 0 : esperanza_vida;
            const color = this.getColor(index.orden, esperanza_vida);
            countryData.push({id, name, value, color, grade: esperanza_vida});
          }else if(index.orden === 3){
            const value = !cobertura_sanitaria ? 0 : cobertura_sanitaria;
            const color = this.getColor(index.orden, cobertura_sanitaria);
            countryData.push({id, name, value, color, grade: cobertura_sanitaria});
          }else if(index.orden === 4){
            const value = !obesidad_adultos ? 0 : obesidad_adultos;
            const color = this.getColor(index.orden, obesidad_adultos);
            countryData.push({id, name, value, color, grade: obesidad_adultos});
          }else if(index.orden === 5){
            const value = !ingresos_capita ? 0 : ingresos_capita;
            const color = this.getColor(index.orden, ingresos_capita);
            countryData.push({id, name, value, color, grade: ingresos_capita});
          }else if(index.orden === 6){
            const value = !acceso_electricidad ? 0 : acceso_electricidad;
            const color = this.getColor(index.orden, acceso_electricidad);
            countryData.push({id, name, value, color, grade: acceso_electricidad});
          }else if(index.orden === 7){
            const value = !nivel_educativo ? 0 : nivel_educativo;
            const color = this.getColor(index.orden, nivel_educativo);
            countryData.push({id, name, value, color, grade: nivel_educativo});
          }else if(index.orden === 8){
            const value = !apoyo_social ? 0 : apoyo_social;
            const color = this.getColor(index.orden, apoyo_social);
            countryData.push({id, name, value, color, grade: apoyo_social});
          }else if(index.orden === 9){
            const value = !indice_democracia ? 0 : indice_democracia;
            const color = this.getColor(index.orden, indice_democracia);
            countryData.push({id, name, value, color, grade: indice_democracia});
          }else if(index.orden === 10){
            const value = !equidad ? 0 : equidad;
            const color = this.getColor(index.orden, equidad);
            countryData.push({id, name, value, color, grade: equidad});
          }else if(index.orden === 11){
            const value = !tasa_empleo ? 0 : tasa_empleo;
            const color = this.getColor(index.orden, tasa_empleo);
            countryData.push({id, name, value, color, grade: tasa_empleo});
          }else if(index.orden === 12){
            const value = !indice_competitividad ? 0 : indice_competitividad;
            const color = this.getColor(index.orden, indice_competitividad);
            countryData.push({id, name, value, color, grade: indice_competitividad});
          }else if(index.orden === 13){
            const value = !indice_paz_global ? 0 : indice_paz_global;
            const color = this.getColor(index.orden, indice_paz_global);
            countryData.push({id, name, value, color, grade: indice_paz_global});
          }else if(index.orden === 14){
            const value = !transparencia ? 0 : transparencia;
            const color = this.getColor(index.orden, transparencia);
            countryData.push({id, name, value, color, grade: transparencia});
          }
        });
        this.showRanking(countryData, index.orderSort);
      });
    });
  }

  getColor = (indexOrden, value) => {
    if(indexOrden === 1){
      if(value === false){
        return '#D6D6DA';
      }else if(value > 90){
        return 'rgb(0, 63, 92)';
      }else if(value <= 90 && value >= 85){
        return 'rgb(68, 78, 134)';
      }else if(value < 85 && value >= 80){
        return 'rgb(149, 81, 150)';
      }else if(value < 80 && value >= 75){
        return 'rgb(221, 80, 128)';
      }else if(value < 75 && value >= 70){
        return 'rgb(255, 110, 84)';
      }else if(value < 70){
        return 'rgb(255, 166, 1)';
      }
    }else if(indexOrden === 2){
      if(value === false){
        return '#D6D6DA';
      }else if(value > 90){
        return 'rgb(0, 63, 92)';
      }else if(value <= 90 && value >= 85){
        return 'rgb(68, 78, 134)';
      }else if(value < 85 && value >= 80){
        return 'rgb(149, 81, 150)';
      }else if(value < 80 && value >= 75){
        return 'rgb(221, 80, 128)';
      }else if(value < 75 && value >= 70){
        return 'rgb(255, 110, 84)';
      }else if(value < 70){
        return 'rgb(255, 166, 1)';
      }
    }else if(indexOrden === 3){
      if(value === false){
        return '#D6D6DA';
      }else if(value > 90){
        return 'rgb(0, 63, 92)';
      }else if(value <= 90 && value >= 80){
        return 'rgb(68, 78, 134)';
      }else if(value < 80 && value >= 70){
        return 'rgb(149, 81, 150)';
      }else if(value < 70 && value >= 60){
        return 'rgb(221, 80, 128)';
      }else if(value < 60 && value >= 50){
        return 'rgb(255, 110, 84)';
      }else if(value < 50){
        return 'rgb(255, 166, 1)';
      }
    }else if(indexOrden === 4){
      if(value === false){
        return '#D6D6DA';
      }else if(value <= 7.39){
        return 'rgb(0, 63, 92)';
      }else if(value >= 7.4 && value <= 9.5){
        return 'rgb(68, 78, 134)';
      }else if(value >= 9.6 && value <= 10.7){
        return 'rgb(149, 81, 150)';
      }else if(value >= 10.8 && value <= 11.9){
        return 'rgb(221, 80, 128)';
      }else if(value >= 12 && value <= 14.9){
        return 'rgb(255, 110, 84)';
      }else if(value >= 15){
        return 'rgb(255, 166, 1)';
      }
    }else if(indexOrden === 5){
      if(value === false){
        return '#D6D6DA';
      }else if(value > 500000){
        return 'rgb(0, 63, 92)';
      }else if(value <= 500000 && value >= 400000){
        return 'rgb(68, 78, 134)';
      }else if(value < 400000 && value >= 300000){
        return 'rgb(149, 81, 150)';
      }else if(value < 300000 && value >= 200000){
        return 'rgb(221, 80, 128)';
      }else if(value < 200000 && value >= 100000){
        return 'rgb(255, 110, 84)';
      }else if(value < 100000){
        return 'rgb(255, 166, 1)';
      }
    }else if(indexOrden === 6){
      if(value === false){
        return '#D6D6DA';
      }else if(value >= 99){
        return 'rgb(0, 63, 92)';
      }else if(value <= 98.99 && value >= 96){
        return 'rgb(68, 78, 134)';
      }else if(value <= 95.99 && value >= 93){
        return 'rgb(149, 81, 150)';
      }else if(value <= 92.99 && value >= 90){
        return 'rgb(221, 80, 128)';
      }else if(value <= 89.99 && value >= 87){
        return 'rgb(255, 110, 84)';
      }else if(value <= 86.99){
        return 'rgb(255, 166, 1)';
      }
    }else if(indexOrden === 7){
      if(value === false){
        return '#D6D6DA';
      }else if(value > 80){
        return 'rgb(0, 63, 92)';
      }else if(value <= 80 && value >= 65){
        return 'rgb(68, 78, 134)';
      }else if(value < 65 && value >= 49){
        return 'rgb(149, 81, 150)';
      }else if(value < 49 && value >= 33){
        return 'rgb(221, 80, 128)';
      }else if(value < 33 && value >= 16){
        return 'rgb(255, 110, 84)';
      }else if(value < 16){
        return 'rgb(255, 166, 1)';
      }
    }else if(indexOrden === 8){
      if(value === false){
        return '#D6D6DA';
      }else if(value < 16){
        return 'rgb(0, 63, 92)';
      }else if(value >= 16 && value <= 33){
        return 'rgb(68, 78, 134)';
      }else if(value > 33 && value <= 49){
        return 'rgb(149, 81, 150)';
      }else if(value > 49 && value <= 65){
        return 'rgb(221, 80, 128)';
      }else if(value > 65 && value <= 80){
        return 'rgb(255, 110, 84)';
      }else if(value > 80){
        return 'rgb(255, 166, 1)';
      }
    }else if(indexOrden === 9){
      if(value === false){
        return '#D6D6DA';
      }else if(value > 8300){
        return 'rgb(0, 63, 92)';
      }else if(value <= 8300 && value >= 6600){
        return 'rgb(68, 78, 134)';
      }else if(value < 6600 && value >= 5000){
        return 'rgb(149, 81, 150)';
      }else if(value < 5000 && value >= 3300){
        return 'rgb(221, 80, 128)';
      }else if(value < 3300 && value >= 1600){
        return 'rgb(255, 110, 84)';
      }else if(value < 1600){
        return 'rgb(255, 166, 1)';
      }
    }else if(indexOrden === 10){
      if(value === false){
        return '#D6D6DA';
      }else if(value < 1){
        return 'rgb(0, 63, 92)';
      }else if(value >= 1 && value <= 2){
        return 'rgb(68, 78, 134)';
      }else if(value > 2 && value <= 3){
        return 'rgb(149, 81, 150)';
      }else if(value > 3 && value <= 6){
        return 'rgb(221, 80, 128)';
      }else if(value > 6 && value <= 7.5){
        return 'rgb(255, 110, 84)';
      }else if(value > 7.5){
        return 'rgb(255, 166, 1)';
      }
    }else if(indexOrden === 11){
      if(value === false){
        return '#D6D6DA';
      }else if(value > 90){
        return 'rgb(0, 63, 92)';
      }else if(value <= 90 && value >= 80){
        return 'rgb(68, 78, 134)';
      }else if(value < 80 && value >= 70){
        return 'rgb(149, 81, 150)';
      }else if(value < 70 && value >= 60){
        return 'rgb(221, 80, 128)';
      }else if(value < 60 && value >= 50){
        return 'rgb(255, 110, 84)';
      }else if(value < 50){
        return 'rgb(255, 166, 1)';
      }
    }else if(indexOrden === 12){
      if(value === false){
        return '#D6D6DA';
      }else if(value > 90){
        return 'rgb(0, 63, 92)';
      }else if(value <= 90 && value >= 80){
        return 'rgb(68, 78, 134)';
      }else if(value < 80 && value >= 70){
        return 'rgb(149, 81, 150)';
      }else if(value < 70 && value >= 60){
        return 'rgb(221, 80, 128)';
      }else if(value < 60 && value >= 50){
        return 'rgb(255, 110, 84)';
      }else if(value < 50){
        return 'rgb(255, 166, 1)';
      }
    }else if(indexOrden === 13){
      if(value === false){
        return '#D6D6DA';
      }else if(value < 1){
        return 'rgb(0, 63, 92)';
      }else if(value >= 1 && value <= 2){
        return 'rgb(68, 78, 134)';
      }else if(value > 2 && value <= 2.54){
        return 'rgb(149, 81, 150)';
      }else if(value > 2.54 && value <= 2.92){
        return 'rgb(221, 80, 128)';
      }else if(value > 2.92 && value <= 3.9){
        return 'rgb(255, 110, 84)';
      }else if(value > 3.9){
        return 'rgb(255, 166, 1)';
      }
    }else if(indexOrden === 14){
      if(value === false){
        return '#D6D6DA';
      }else if(value >= 126){
        return 'rgb(0, 63, 92)';
      }else if(value <= 125.99 && value >= 101){
        return 'rgb(68, 78, 134)';
      }else if(value <= 100.99 && value >= 76){
        return 'rgb(149, 81, 150)';
      }else if(value <= 75.99 && value >= 51){
        return 'rgb(221, 80, 128)';
      }else if(value <= 50.99 && value >= 26){
        return 'rgb(255, 110, 84)';
      }else if(value <= 25.99){
        return 'rgb(255, 166, 1)';
      }
    }
  }

  showRanking = (countryData, orderSort) => {
    let activeRanking = [];
    let sortRanking;
    if(orderSort === 'desc'){
      sortRanking = countryData.sort((a, b) => parseFloat(b.value) - parseFloat(a.value));
    }else if(orderSort === 'asc'){
      sortRanking = countryData.sort((a, b) => parseFloat(a.value) - parseFloat(b.value));
    }
    sortRanking.forEach( (state, key) => {
      const place = key + 1;
      activeRanking.push({
        id: state.id,
        place,
        name: state.name,
        color: state.color,
        grade: state.grade
      })
    });
    this.setState({ activeRanking });
  }

  handleSubmit = (values) => {
    const { anio, indice } = values;
    this.state.indexesList.forEach( index => {
      if(index.nombre === indice){
        this.getCountryData(index, anio);
        this.setState({ activeYear: anio, activeIndex: index });
      }
    });
  }

  renderBody(){
    return (
      <div className="results-country-container">
        <div className="title_section">
          <h2>Capital Socioeconómico y Cultural</h2>
          <p>Conoce la situación, propuestas y distribución de sostenibilidad social y economía regenerativa de nuestro país</p>
        </div>
        <div className="content">
          <FilterResultsCountryForm anios={this.state.yearList} indices={this.state.indexesList} onSubmit={this.handleSubmit} />
          <div className="map-row">
            <div className="col-map">
              <RenderMap index={this.state.activeIndex} data={this.state.activeRanking} />
            </div>
            <div className="col-ranking">
              <Table>
                <Thead>
                  <Tr>
                    <Th>Clasificación</Th>
                    <Th>Estado</Th>
                    <Th>Calificación</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {
                    this.state.activeRanking.map( (state) => {
                      const nationalClass = state.name === 'Nacional' ? 'national' : '';
                      return(
                          <Tr key={state.id} className={nationalClass}>
                            <Td>{state.place}</Td>
                            <Td className="state"><span style={{ backgroundColor: state.color }}/>{state.name}</Td>
                            <Td>{state && state.grade !== false ? state.grade.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : 'NA'}</Td>
                          </Tr>
                      )
                    })
                  }
                </Tbody>
              </Table>
            </div>
          </div>
        </div>
      </div>
    );
  }

  render() {
    return (
      <AppFrame body={this.renderBody()} />
    );
  }
}

export default CSCCountryContainer;
