import React, {Component} from 'react';
import AppFrame from '../../components/AppFrame';
import Contact from "../../components/contact/Contact";
import Swal from 'sweetalert2';
import firebase from "../../../Firebase";

class ContactContainer extends Component {
    constructor(props){
        super(props);
        this.db = firebase.firestore();
        this.tableContact = this.db.collection('contacto');
    }

    handleSubmit = (values) => {
        const { nombre, correo, asunto, mensaje } = values;
        const fecha = new Date();
        this.tableContact.add({ nombre, correo, asunto, mensaje, fecha }).then( () => {
            Swal.fire({
                title: '¡Gracias!',
                text: 'Tu mensaje ha sido enviado exitosamente.',
                icon: 'success',
                confirmButtonText: 'Continuar'
            }).then( () => {
                window.location.reload();
            });
        }).catch( () => {
            Swal.fire({
                title: '¡Lo sentimos!',
                text: 'Hubo un problema enviando tu mensaje.',
                icon: 'warning',
                confirmButtonText: 'Continuar'
            }).then( () => {});
        });
    }

    renderBody() {
        return (
            <div className="contact-container">
                <div className="title_section">
                    <h2>Contáctanos</h2>
                    <p>Queremos conocer tu opinión, dudas o sugerencias.</p>
                </div>
                <div className="content">
                    <Contact onSubmit={this.handleSubmit} />
                </div>
            </div>
        );
    }

    render() {
        return (
            <AppFrame body={this.renderBody()}/>
        );
    }
}

export default ContactContainer;
