import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../css/site.css';
import '../css/font-awesome.min.css';
// noinspection ES6CheckImport
import {withRouter, NavLink} from "react-router-dom";
import logo from "../images/logo_color.png";
import {setActiveMenu} from "../../actions/menuActions";
import { connect } from 'react-redux';
import { Dropdown } from "react-bootstrap";

class AppHeader extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  handleChange = (event) => {
    let menu = event.target.value;
    this.props.setActiveMenu(menu);
    this.props.history.push(menu);
  }

  render() {
    return (
        <div className="header">
          <div className="main_menu">
            <div className="logo">
              <NavLink exact to="/" activeClassName="active"><img src={logo} alt="La Realidad de México" /></NavLink>
            </div>
            <div className="menu list_menu">
              <NavLink exact to="/" activeClassName="active">Inicio</NavLink>
              <Dropdown>
                <Dropdown.Toggle>Capital Socioeconómico y Cultural</Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item href="/csc-nacional">Nivel Nacional</Dropdown.Item>
                  <Dropdown.Item href="/csc-estatal">Nivel Estatal</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
              <Dropdown>
                <Dropdown.Toggle>Capital Natural</Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item href="/cn-nacional">Nivel Nacional</Dropdown.Item>
                  <Dropdown.Item href="/cn-estatal">Nivel Estatal</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
              <NavLink exact to="/contacto" activeClassName="active">Contacto</NavLink>
              <a href="https://twitter.com/LaRealidad_Mx" target="_blank" rel="noopener noreferrer"><i className="fab fa-twitter"/> Síguenos en Twitter</a>
            </div>
          </div>
          <div className="menu-mobile">
            <div className="contenedor-menu">
              <div className="logo">
                <NavLink exact to="/" activeClassName="active"><img src={logo} alt="La Realidad de México" /></NavLink>
              </div>
              <div className="select">
                <select id="menu-select" onChange={this.handleChange} value={this.props.menu}>
                  <option value="">Menú</option>
                  <option value="/">Inicio</option>
                  <option value="/csc-nacional">Capital Socioeconómico y Cultural a Nivel Nacional</option>
                  <option value="/csc-estatal">Capital Socioeconómico y Cultural a Nivel Estatal</option>
                  <option value="/cn-nacional">Capital Natural a Nivel Nacional</option>
                  <option value="/cn-estatal">Capital Natural a Nivel Estatal</option>
                  <option value="/contacto">Contacto</option>
                </select>
              </div>
            </div>
            <div className="footer">
              <div className="social">
                <a href="https://twitter.com/LaRealidad_Mx" target="_blank" rel="noopener noreferrer"><i className="fab fa-twitter"/> Síguenos en Twitter</a>
              </div>
            </div>
          </div>
        </div>
    );
  }
}

const mapStateToProps = (state)=>{
  return {
    menu: state.menu.activeMenu
  }
}

const mapDispatchToProps= (dispatch)=>{
  return {
    setActiveMenu: (menu) => {
      dispatch(setActiveMenu(menu))
    }
  }
}

export default connect(mapStateToProps,mapDispatchToProps)(withRouter(AppHeader));
