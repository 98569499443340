import React, {Component} from 'react';
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

class CircularProgress extends Component {
    render() {
        const { percentage, value, name, description, color } = this.props;
        return (
            <div className="radial-chart">
                <CircularProgressbar value={percentage} text={`${value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`} styles={{
                    path: {stroke: color},
                    text: {fill: color, fontSize: '14px', fontWeight: 700, fontFamily: 'Josefin Sans'}
                    }}
                />
                <p style={{ color: color }}>{name}</p>
                <div className="hint">
                    <p>{description}</p>
                </div>
            </div>
        );
    }
}

export default CircularProgress;
