import React, {Component} from 'react';
import AppFrame from "../../components/AppFrame";
import firebase from "../../../Firebase";
import FilterResultsCountryForm from "../../components/results-filter/FilterResultsCountry";
import RenderMap from "../../components/map-render/RenderMap";
import {Table, Tbody, Td, Th, Thead, Tr} from "react-super-responsive-table";

class CNCountryContainer extends Component {
    constructor(props){
        super(props);
        this.db = firebase.firestore();
        this.state = {
            yearList: [],
            indexesList: [],
            activeIndex: { key: '', nombre: '', referencia: '', descripcion: '', orden: 0, orderSort: 'desc'},
            activeYear: new Date().getFullYear(),
            activeRanking: []
        };
        this.tableIndexes = this.db.collection('cn_indices').orderBy('orden','asc');
        this.tableCN = this.db.collection('capital_natural');
    }

    componentDidMount() {
        this.tableIndexes.onSnapshot(this.getIndexes);
        this.tableCN.onSnapshot(this.getYears);
    }

    getIndexes = (querySnapshot) => {
        const indexesList = [];
        querySnapshot.forEach((doc) => {
            const { nombre, referencia, descripcion, orden, orderSort } = doc.data();
            indexesList.push({ key: doc.id, nombre, referencia, descripcion, orden, orderSort});
        });
        if(indexesList.length > 0){
            this.getCountryData(indexesList[0], this.state.activeYear);
            this.setState({ indexesList, activeIndex: indexesList[0] });
        }
    }

    getYears = (querySnapshot) => {
        const yearList = [];
        querySnapshot.forEach((doc) => {
            const { anio } = doc.data();
            yearList.push({ key: doc.id, nombre: anio });
        });
        this.setState({ yearList });
    }

    getCountryData = (index, year) => {
        let countryData = [];
        this.tableCN.where('anio','==',Number(year)).get().then( snap => {
            snap.forEach( doc => {
                const { estados } = doc.data();
                estados.forEach( estado => {
                    const {
                        id,
                        name,
                        emisiones_cov,
                        degradacion_quimica,
                        degradacion_fisica,
                        consumo_agua,
                        contaminacion_agua,
                        importacion_agua_virtual,
                        perdida_vegetal,
                        calidad_aire,
                        material_footprint,
                        especies_rojo
                    } = estado;
                    if(index.orden === 1){
                        const value = !emisiones_cov ? 0 : emisiones_cov;
                        const color = this.getColor(index.orden, emisiones_cov);
                        countryData.push({id, name, value, color, grade: emisiones_cov});
                    }else if(index.orden === 2){
                        const value = !degradacion_quimica ? 0 : degradacion_quimica;
                        const color = this.getColor(index.orden, degradacion_quimica);
                        countryData.push({id, name, value, color, grade: degradacion_quimica});
                    }else if(index.orden === 3){
                        const value = !degradacion_fisica ? 0 : degradacion_fisica;
                        const color = this.getColor(index.orden, degradacion_fisica);
                        countryData.push({id, name, value, color, grade: degradacion_fisica});
                    }else if(index.orden === 4){
                        const value = !consumo_agua ? 0 : consumo_agua;
                        const color = this.getColor(index.orden, consumo_agua);
                        countryData.push({id, name, value, color, grade: consumo_agua});
                    }else if(index.orden === 5){
                        const value = !contaminacion_agua ? 0 : contaminacion_agua;
                        const color = this.getColor(index.orden, contaminacion_agua);
                        countryData.push({id, name, value, color, grade: contaminacion_agua});
                    }else if(index.orden === 6){
                        const value = !importacion_agua_virtual ? 0 : importacion_agua_virtual;
                        const color = this.getColor(index.orden, importacion_agua_virtual);
                        countryData.push({id, name, value, color, grade: importacion_agua_virtual});
                    }else if(index.orden === 7){
                        const value = !perdida_vegetal ? 0 : perdida_vegetal;
                        const color = this.getColor(index.orden, perdida_vegetal);
                        countryData.push({id, name, value, color, grade: perdida_vegetal});
                    }else if(index.orden === 8){
                        const value = !calidad_aire ? 0 : calidad_aire;
                        const color = this.getColor(index.orden, calidad_aire);
                        countryData.push({id, name, value, color, grade: calidad_aire});
                    }else if(index.orden === 9){
                        const value = !material_footprint ? 0 : material_footprint;
                        const color = this.getColor(index.orden, material_footprint);
                        countryData.push({id, name, value, color, grade: material_footprint});
                    }else if(index.orden === 10){
                        const value = !especies_rojo ? 0 : especies_rojo;
                        const color = this.getColor(index.orden, especies_rojo);
                        countryData.push({id, name, value, color, grade: especies_rojo});
                    }
                });
                this.showRanking(countryData, index.orderSort);
            });
        });
    }

    getColor = (indexOrden, value) => {
        if(indexOrden === 1){
            if(value === false){
                return '#D6D6DA';
            }else if(value <= 50.9){
                return 'rgb(0, 63, 92)';
            }else if(value >= 51 && value <= 150.9){
                return 'rgb(68, 78, 134)';
            }else if(value >= 151 && value <= 250.9){
                return 'rgb(149, 81, 150)';
            }else if(value >= 251 && value <= 350.9){
                return 'rgb(221, 80, 128)';
            }else if(value >= 351 && value <= 450.9){
                return 'rgb(255, 110, 84)';
            }else if(value >= 451){
                return 'rgb(255, 166, 1)';
            }
        }else if(indexOrden === 2){
            if(value === false){
                return '#D6D6DA';
            }else if(value <= 5.9){
                return 'rgb(0, 63, 92)';
            }else if(value >= 6 && value <= 10.9){
                return 'rgb(68, 78, 134)';
            }else if(value >= 11 && value <= 15.9){
                return 'rgb(149, 81, 150)';
            }else if(value >= 16 && value <= 20.9){
                return 'rgb(221, 80, 128)';
            }else if(value >= 21 && value <= 25.9){
                return 'rgb(255, 110, 84)';
            }else if(value >= 26){
                return 'rgb(255, 166, 1)';
            }
        }else if(indexOrden === 3){
            if(value === false){
                return '#D6D6DA';
            }else if(value <= 5.9){
                return 'rgb(0, 63, 92)';
            }else if(value >= 6 && value <= 10.9){
                return 'rgb(68, 78, 134)';
            }else if(value >= 11 && value <= 15.9){
                return 'rgb(149, 81, 150)';
            }else if(value >= 16 && value <= 20.9){
                return 'rgb(221, 80, 128)';
            }else if(value >= 21 && value <= 25.9){
                return 'rgb(255, 110, 84)';
            }else if(value >= 26){
                return 'rgb(255, 166, 1)';
            }
        }else if(indexOrden === 4){
            if(value === false){
                return '#D6D6DA';
            }else if(value <= 0.99){
                return 'rgb(0, 63, 92)';
            }else if(value >= 1 && value <= 1.99){
                return 'rgb(68, 78, 134)';
            }else if(value >= 2 && value <= 2.99){
                return 'rgb(149, 81, 150)';
            }else if(value >= 3 && value <= 3.99){
                return 'rgb(221, 80, 128)';
            }else if(value >= 4 && value <= 4.99){
                return 'rgb(255, 110, 84)';
            }else if(value >= 5){
                return 'rgb(255, 166, 1)';
            }
        }else if(indexOrden === 5){
            if(value === false){
                return '#D6D6DA';
            }else if(value <= 0.99){
                return 'rgb(0, 63, 92)';
            }else if(value >= 1 && value <= 1.99){
                return 'rgb(68, 78, 134)';
            }else if(value >= 2 && value <= 2.99){
                return 'rgb(149, 81, 150)';
            }else if(value >= 3 && value <= 3.99){
                return 'rgb(221, 80, 128)';
            }else if(value >= 4 && value <= 4.99){
                return 'rgb(255, 110, 84)';
            }else if(value >= 5){
                return 'rgb(255, 166, 1)';
            }
        }else if(indexOrden === 6){
            if(value === false){
                return '#D6D6DA';
            }else if(value <= 0.2){
                return 'rgb(0, 63, 92)';
            }else if(value >= 0.21 && value <= 0.4){
                return 'rgb(68, 78, 134)';
            }else if(value >= 0.41 && value <= 0.6){
                return 'rgb(149, 81, 150)';
            }else if(value >= 0.61 && value <= 0.8){
                return 'rgb(221, 80, 128)';
            }else if(value >= 0.81 && value <= 1){
                return 'rgb(255, 110, 84)';
            }else if(value > 1){
                return 'rgb(255, 166, 1)';
            }
        }else if(indexOrden === 7){
            if(value === false){
                return '#D6D6DA';
            }else if(value <= 0){
                return 'rgb(0, 63, 92)';
            }else if(value >= 0.1 && value <= 2){
                return 'rgb(68, 78, 134)';
            }else if(value >= 2.1 && value <= 4){
                return 'rgb(149, 81, 150)';
            }else if(value >= 4.1 && value <= 6){
                return 'rgb(221, 80, 128)';
            }else if(value >= 6.1 && value <= 8){
                return 'rgb(255, 110, 84)';
            }else if(value > 8){
                return 'rgb(255, 166, 1)';
            }
        }else if(indexOrden === 8){
            if(value === false){
                return '#D6D6DA';
            }else if(value <= 0.99){
                return 'rgb(0, 63, 92)';
            }else if(value >= 1 && value <= 1.99){
                return 'rgb(68, 78, 134)';
            }else if(value >= 2 && value <= 2.99){
                return 'rgb(149, 81, 150)';
            }else if(value >= 3 && value <= 3.99){
                return 'rgb(221, 80, 128)';
            }else if(value >= 4 && value <= 4.99){
                return 'rgb(255, 110, 84)';
            }else if(value >= 5){
                return 'rgb(255, 166, 1)';
            }
        }else if(indexOrden === 9){
            if(value === false){
                return '#D6D6DA';
            }else if(value <= 10999.99){
                return 'rgb(0, 63, 92)';
            }else if(value >= 11000 && value <= 50999.99){
                return 'rgb(68, 78, 134)';
            }else if(value >= 51000 && value <= 100999.99){
                return 'rgb(149, 81, 150)';
            }else if(value >= 101000 && value <= 150999.99){
                return 'rgb(221, 80, 128)';
            }else if(value >= 151000 && value <= 200999.99){
                return 'rgb(255, 110, 84)';
            }else if(value >= 201000){
                return 'rgb(255, 166, 1)';
            }
        }else if(indexOrden === 10){
            if(value === false){
                return '#D6D6DA';
            }else if(value <= 0.99){
                return 'rgb(0, 63, 92)';
            }else if(value >= 1 && value <= 50.99){
                return 'rgb(68, 78, 134)';
            }else if(value >= 51 && value <= 100.99){
                return 'rgb(149, 81, 150)';
            }else if(value >= 101 && value <= 150.99){
                return 'rgb(221, 80, 128)';
            }else if(value >= 151 && value <= 200.99){
                return 'rgb(255, 110, 84)';
            }else if(value >= 201){
                return 'rgb(255, 166, 1)';
            }
        }
    }

    showRanking = (countryData, orderSort) => {
        let activeRanking = [];
        let sortRanking;
        if(orderSort === 'desc'){
            sortRanking = countryData.sort((a, b) => parseFloat(b.value) - parseFloat(a.value));
        }else if(orderSort === 'asc'){
            sortRanking = countryData.sort((a, b) => parseFloat(a.value) - parseFloat(b.value));
        }
        sortRanking.forEach( (state, key) => {
            const place = key + 1;
            activeRanking.push({
                id: state.id,
                place,
                name: state.name,
                color: state.color,
                grade: state.grade
            })
        });
        this.setState({ activeRanking });
    }

    handleSubmit = (values) => {
        const { anio, indice } = values;
        this.state.indexesList.forEach( index => {
            if(index.nombre === indice){
                this.getCountryData(index, anio);
                this.setState({ activeYear: anio, activeIndex: index });
            }
        });
    }

    renderBody(){
        return (
            <div className="results-country-container">
                <div className="title_section">
                    <h2>Capital Natural</h2>
                    <p>Conoce la situación, propuestas y distribución del techo ecológico de nuestro país</p>
                </div>
                <div className="content">
                    <FilterResultsCountryForm anios={this.state.yearList} indices={this.state.indexesList} onSubmit={this.handleSubmit} />
                    <div className="map-row">
                        <div className="col-map">
                            <RenderMap index={this.state.activeIndex} data={this.state.activeRanking} />
                        </div>
                        <div className="col-ranking">
                            <Table>
                                <Thead>
                                    <Tr>
                                        <Th>Clasificación</Th>
                                        <Th>Estado</Th>
                                        <Th>Calificación</Th>
                                    </Tr>
                                </Thead>
                                <Tbody>
                                    {
                                        this.state.activeRanking.map( (state) => {
                                            const nationalClass = state.name === 'Nacional' ? 'national' : '';
                                            return(
                                                <Tr key={state.id} className={nationalClass}>
                                                    <Td>{state.place}</Td>
                                                    <Td className="state"><span style={{ backgroundColor: state.color }}/>{state.name}</Td>
                                                    <Td>{state && state.grade !== false ? state.grade.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : 'NA'}</Td>
                                                </Tr>
                                            )
                                        })
                                    }
                                </Tbody>
                            </Table>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    render() {
        return (
            <AppFrame body={this.renderBody()} />
        );
    }
}

export default CNCountryContainer;
