import React, { Component } from 'react';
import { reduxForm, Field } from 'redux-form';
import ReCAPTCHA from "react-google-recaptcha";
import {validate} from './validate';

class Contact extends Component {
    constructor(props) {
        super(props);
        this.state = {
            allowSubmit: true
        };
    }

    renderField = ({input, meta, type, label, name}) => (
        <div className="form-group">
            <label className="control-label" htmlFor={name}>{label}</label>
            <input className="form-control" {...input} type={!type ? 'text' : type} />
            { meta.touched && meta.error && <span>{meta.error}</span> }
        </div>
    );

    renderTextArea = ({input, meta, label, name}) => (
        <div className="form-group">
            <label className="control-label" htmlFor={name}>{label}</label>
            <textarea className="form-control" {...input} rows="5" required />
            { meta.touched && meta.error && <span>{meta.error}</span> }
        </div>
    );

    onChange = (value) => {
        if(value !== ''){
            this.setState({allowSubmit: false});
        }
    }

    render() {
        const {handleSubmit} = this.props;
        return (
            <div className="form-website-container">
                <form onSubmit={handleSubmit}>
                    <Field name="nombre" label="Nombre" component={this.renderField} type="text"/>
                    <Field name="correo" label="Correo" component={this.renderField} type="email" />
                    <Field name="asunto" label="Asunto" component={this.renderField} type="text" />
                    <Field name="mensaje" label="Mensaje" component={this.renderTextArea} />
                    <div className="captcha-website">
                        <ReCAPTCHA
                            sitekey="6LfmLPMUAAAAAHTUHR7a9SmzgsV52nuG3SJXDvap"
                            onChange={this.onChange}
                            theme="light"
                        />
                    </div>
                    <button type="submit" disabled={this.state.allowSubmit}>Enviar</button>
                </form>
            </div>
        );
    }
}

const ContactForm = reduxForm({
    form: 'ContactForm',
    validate
})(Contact);

export default ContactForm;
